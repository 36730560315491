<template>
  <div>
    <Loading version="v3"/>
  </div>
</template>

<script>
import { Loading } from '@/components/misc';

export default {
  name: 'DepositCardCallback',
  components: {
    Loading,
  },

  mounted() {
    window.top.postMessage({
      type: 'circle-deposit-card-callback',
      id: this.$route.params.id,
      succeeded: this.$route.params.succeeded === 'success'
    }, window.location.origin);
  }
};
</script>

<style scoped>

</style>
